



































































import { Vue, Component, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

import Layout from '@/layouts/LayoutDefault.vue';
import TemplateDate from '@/templates/TemplateDate.vue';

import { ActionInterface } from '@/types/breadcrumb';
import { PaginatedData } from '@/types/paginatedData';

import Product from '@modules/chemical-risk/entities/Product';
import CheInventoryFilter from '@modules/chemical-risk/components/form/CheInventoryFilter.vue';

const cheModule = namespace('cheRisk');

@Component({
  components: {
    Layout,
    CheInventoryFilter,
    TemplateDate,
  },
})
export default class InventoryView extends Vue {
  @cheModule.Action('loadProducts')
  loadProducts!: (payload: { page: number, filters: any }) => PaginatedData<Product>;

  @cheModule.Action('deleteProduct')
  deleteProduct: any;

  products: Product[] = [];
  loading: boolean = false;
  total: number = 1;

  filters: any = {
    name: '',
    vendor: '',
    status: '',
    affectation: '',
    page: 1,
    order: '',
    orderBy: '',
    limit: 10,
  };

  tableProps: any = {
    border: true,
    stripe: true,
    defaultSort: { prop: 'position', order: 'ascending' },
  };

  paginationProps: any = {
    pageSizes: [10, 20, 50, 100],
    layout: 'prev, pager, next, jumper, ->, total, slot',
  };

  @Watch('filters', { immediate: true, deep: true })
  onFilterChange() {
    if (!this.loading) {
      this.load();
    }
  }

  get actions(): ActionInterface[] {
    return [
      {
        label: 'Ajouter un produit',
        route: 'chemi-risks-inventory-add',
      },
    ];
  }

  handleEdit(product: any) {
    this.$router.push({ name: 'chemi-risks-inventory-edit', params: { id: product.id } });
  }

  handleDelete(product: any) {
    this.$confirm('Que souhaitez-vous faire ? Indiquer que le produit n’est plus utilisé dans l’entreprise ou le supprimer définitivement ?', 'Attention', {
      confirmButtonText: 'Supprimer le produit',
      cancelButtonText: 'Sortir le produit',
      distinguishCancelAndClose: true,
      type: 'warning',
    }).then(async () => {
      await this.deleteProduct(product.id);
      await this.load();
      this.$message({
        type: 'success',
        message: 'Produit supprimé',
      });
    }).catch((action) => {
      if (action === 'cancel') {
        this.$router.push({ name: 'chemi-risks-inventory-delete', params: { id: product.id, status: '3' } });
      } else {
        this.$message({
          type: 'info',
          message: 'Suppression annulée',
        });
      }
    });
  }

  handleSortChange(payload: { type: string, sort: any }) {
    const { type, sort } = payload;
    if (type !== 'sort') {
      return;
    }

    if (sort.order !== null) {
      this.filters.order = sort.order === 'ascending' ? 'ASC' : 'DESC';
      this.filters.orderBy = sort.prop;
    } else {
      this.filters.order = '';
      this.filters.orderBy = '';
    }
  }

  async load() {
    this.loading = true;
    const data = await this.loadProducts({ page: this.filters.page, filters: this.filters });
    this.products = data.items;
    this.total = data.meta.total;
    this.loading = false;
  }
}
