










































import { Component } from 'vue-property-decorator';

import FilterComponent from '@/components/form/FilterComponent';
import StructureFilter from '@/components/form/StructureFilter.vue';

import { ProductEnumName } from '@modules/chemical-risk/types/enum';

@Component({
  components: { StructureFilter },
})
export default class CheInventoryFilter extends FilterComponent {
  filters = {
    name: '',
    vendor: '',
    status: '',
    affectation: '',
    withoutFDS: '',
  };

  productStatusEnum = ProductEnumName;
}
